<template>
  <v-row no-gutters align-content="center" justify="center">
    <h4 v-if="_.isEmpty(userSupervisors)" class="mb-7">
      {{ $t('messages.emptyState', { entity: $tc('models.supervisor.entity') }) }}
    </h4>
    <v-hover v-else v-for="(supervisor, index) in userSupervisors" :key="index" v-slot:default="{ hover }">
      <v-card width="300" class="mx-5 my-5"  :height="hover ? 'auto' : 325" :elevation="hover ? 12 : 0"
        @click="$router.push({ name:'UserPublicProfile', params: { uuid: supervisor.id }});">
        <v-img :src="getFileUrl(supervisor.avatar)" :height="hover ? 300 : 250"
         class="transition-ease-in-out" @error="hasError=true">
          <template v-slot:placeholder>
            <v-row no-gutters class="primary fill-height ma-0" align="center" justify="center">
              <span v-if="hasError || _.isEmpty(supervisor.avatar)" class="name-initials-size white--text headline">
                {{ supervisor.initials }}
              </span>
            </v-row>
          </template>
        </v-img>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline">{{ supervisor.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ supervisor.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list key="list" v-if="hover">
          <v-list-item>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-subheader class="fill-height overline m-0 p-0" v-text="$t('models.user.attributes.jobTitle')" />
              </v-col>
              <v-col cols="6" class="py-0">{{ supervisor.jobTitle }}</v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-subheader class="fill-height overline m-0 p-0" v-text="$t('models.user.attributes.phoneNumber')" />
              </v-col>
              <v-col cols="6" class="py-0">{{ supervisor.phoneNumber }}</v-col>
            </v-row>
          </v-list-item>
          <v-subheader class="fill-height overline mt-4" v-text="$t('layout.userMenu.permissions')" />
          <v-list-item v-for="(permission, index) in supervisor.supervisingPermissions" :key="index"
            class="mx-3 text-xs">
            {{ $t(`availablePermissions.${availablePermissions[permission.sym]}.title`) | uppercase }}
          </v-list-item>
        </v-list>
      </v-card>
    </v-hover>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {availablePermissions} from 'constants';

export default {
  name: 'SupervisorsCard',
  data() {
    return {
      availablePermissions: availablePermissions,
      hasError: false
    };
  },
  computed: mapGetters(['userSupervisors']),
  methods: mapActions(['getUserSupervisors']),
  created() {
    this.getUserSupervisors(this.$route.params.uuid);
  }
};
</script>

<style scoped>
.name-initials-size {
  font-size: 100px !important
}
</style>
